import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import Select from '@ui/data-entry/Select'

/**
 * Component for selecting the design variant.
 *
 * @typedef {object} ThemeVariantSelectProps
 * @property {object} site - The site object
 *
 * @param {ThemeVariantSelectProps} props
 * @returns {JSX.Element}
 */
export function ThemeVariantSelect({ site }) {
  const { t } = useTranslation('designs/adventist')

  const isAdventistNewsThemeSupported = useFeatureFlagEnabled(
    'adventist-news-theme',
    {
      site,
    }
  )
  const isAdventistMinimalThemeSupported = useFeatureFlagEnabled(
    'adventist-minimal-theme',
    { site }
  )

  return (
    <>
      <Select
        label={t('designVariant')}
        help={t('designVariantHelp')}
        name="variant"
        shouldUnregister
        required
      >
        <Select.Placeholder label="-" />
        <Select.Option label={t('designVariant-alps')} value="alps" />
        {isAdventistNewsThemeSupported ? (
          <Select.Option label={t('designVariant-news')} value="news" />
        ) : null}
        {isAdventistMinimalThemeSupported ? (
          <Select.Option label={t('designVariant-minimal')} value="minimal" />
        ) : null}
      </Select>
    </>
  )
}

ThemeVariantSelect.propTypes = {
  site: PropTypes.object.isRequired,
}
