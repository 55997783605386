import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import Select from '@ui/data-entry/Select'

const themeOptions = [
  { name: 'treefrog', label: 'Treefrog' },
  { name: 'ming', label: 'Ming' },
  { name: 'bluejay', label: 'Bluejay' },
  { name: 'iris', label: 'Iris' },
  { name: 'lily', label: 'Lily' },
  { name: 'scarlett', label: 'Scarlett' },
  { name: 'campfire', label: 'Campfire' },
  { name: 'winter', label: 'Winter' },
  { name: 'forest', label: 'Forest' },
  { name: 'cave', label: 'Cave' },
  { name: 'denim', label: 'Denim' },
  { name: 'emperor', label: 'Emperor' },
  { name: 'grapevine', label: 'Grapevine' },
  { name: 'velvet', label: 'Velvet' },
  { name: 'earth', label: 'Earth' },
  { name: 'night', label: 'Night' },
]

/**
 * Component for selecting the design variant.
 *
 * @typedef {object} ColorThemeSelectProps
 * @property {object} site - The site object
 *
 * @param {ColorThemeSelectProps} props
 * @returns {JSX.Element}
 */
export function ColorThemeSelect({ site }) {
  const { t } = useTranslation('designs/adventist')

  const isAdventistMinimalThemeSupported = useFeatureFlagEnabled(
    'adventist-minimal-theme',
    { site }
  )

  useEffect(() => {
    if (isAdventistMinimalThemeSupported) {
      themeOptions.push({ name: 'custom', label: 'Custom' })
    }
  }, [isAdventistMinimalThemeSupported])

  return (
    <Select
      label={t('colorTheme')}
      help={t('colorThemeHelp')}
      name="colorTheme"
      required
      shouldUnregister
    >
      <Select.Placeholder label="-" />
      {themeOptions.map(({ name, label }) => (
        <Select.Option label={label} value={name} key={name} />
      ))}
    </Select>
  )
}

ColorThemeSelect.propTypes = {
  site: PropTypes.object.isRequired,
}
