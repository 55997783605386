import articlesModule from '@modules/articles/register'
import authModule from '@modules/auth/register'
import categoriesModule from '@modules/categories/register'
import clientsModule from '@modules/clients/register'
import countriesModule from '@modules/countries/register'
import coursesModule from '@modules/courses/register'
import dashboardModule from '@modules/dashboard/register'
import dataImportModule from '@modules/data-import/register'
import documentsModule from '@modules/documents/register'
import entitiesModule from '@modules/entities/register'
import eventsModule from '@modules/events/register'
import featureFlagsModule from '@modules/feature-flags/register'
import imagesModule from '@modules/images/register'
import instancesModule from '@modules/instances/register'
import languagesModule from '@modules/languages/register'
import logsModule from '@modules/logs/register'
import mediaLibraryModule from '@modules/media-library/register'
import paymentsModule from '@modules/payments/register'
import personsModule from '@modules/persons/register'
import publicationsModule from '@modules/publications/register'
import sermonsModule from '@modules/sermons/register'
import tasksModule from '@modules/tasks/register'
import usersModule from '@modules/users/register'
import webAuthModule from '@modules/web-auth/register'
import webModule from '@modules/web/register'

const modules = [
  dashboardModule(),
  entitiesModule(),
  authModule(),
  usersModule(),
  mediaLibraryModule(),
  categoriesModule(),
  imagesModule(),
  documentsModule(),
  coursesModule(),
  countriesModule(),
  eventsModule(),
  featureFlagsModule(),
  webModule(),
  webAuthModule(),
  articlesModule(),
  publicationsModule(),
  languagesModule(),
  personsModule(),
  sermonsModule(),
  logsModule(),
  tasksModule(),
  clientsModule(),
  instancesModule(),
  dataImportModule(),
  paymentsModule(),
]

export default function registerModules() {
  const {
    blocksResolver,
    blocksToolbars,
    dashboards,
    dataSources,
    menuItems,
    permissions,
    resources,
    routes,
    searchIndexes,
    tasks,
    translatableBlocks,
  } = modules.reduce(
    (
      acc,
      {
        menu,
        routes,
        permissions,
        resources,
        dashboards,
        dataSources,
        blocksResolver,
        blocksToolbars,
        search,
        translatableBlocks,
        tasks,
      }
    ) => {
      if (menu) {
        acc.menuItems.push(menu)
      }

      if (dashboards) {
        acc.dashboards.push(...dashboards)
      }

      if (dataSources) {
        acc.dataSources = { ...acc.dataSources, ...dataSources }
      }

      if (routes) {
        if (routes.public) {
          acc.routes.public = { ...acc.routes.public, ...routes.public }
        }
        if (routes.private) {
          acc.routes.private = { ...acc.routes.private, ...routes.private }
        }
      }

      if (permissions) {
        acc.permissions = { ...acc.permissions, ...permissions }
      }

      if (resources) {
        acc.resources = { ...acc.resources, ...resources }
      }

      if (blocksResolver) {
        acc.blocksResolver = { ...acc.blocksResolver, ...blocksResolver }
      }

      if (blocksToolbars) {
        acc.blocksToolbars = [...acc.blocksToolbars, ...blocksToolbars]
      }

      if (search?.indexes) {
        acc.searchIndexes = { ...acc.searchIndexes, ...search.indexes }
      }

      if (translatableBlocks) {
        acc.translatableBlocks = {
          ...acc.translatableBlocks,
          ...translatableBlocks,
        }
      }

      if (tasks) {
        acc.tasks = { ...acc.tasks, ...tasks }
      }

      return acc
    },
    {
      blocksResolver: {},
      blocksToolbars: [],
      dashboards: [],
      dataSources: {},
      menuItems: [],
      permissions: {},
      resources: {},
      routes: { public: {}, private: {} },
      search: {},
      tasks: {},
      translatableBlocks: {},
    }
  )

  return {
    blocksResolver,
    blocksToolbars,
    dashboards,
    dataSources,
    menuItems,
    permissions,
    resources,
    routes,
    searchIndexes,
    tasks,
    translatableBlocks,
  }
}
