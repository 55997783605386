import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useDesign } from '@app/designs/hooks/useDesign'
import Button from '@ui/buttons/Button'
import Section from '@ui/data-display/Section'
import ColorPalette from '@ui/data-entry/ColorPalette'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Heading from '@ui/typography/Heading'
import { range } from '@utils/arrays'

const MAX_COLORS = 8

function isPaletteSet(colors = {}) {
  return Object.keys(colors || {}).length > 0
}

export default function ColorsForm({ site, onSubmit }) {
  const { t } = useTranslation(['designs/adventist', 'designs/base'])
  const design = useDesign({ design: 'adventist', site })

  const { colors } = site?.design || {}

  const customColors = Object.keys(colors || {}).filter(c =>
    c.startsWith('color')
  )
  const [colorCount, setColorCount] = useState(customColors.length)

  const nextColor = colorCount + 1
  const canAddColor = nextColor <= MAX_COLORS

  const onAddColor = useCallback(() => {
    if (!canAddColor) return
    setColorCount(nextColor)
  }, [canAddColor, nextColor])

  const formData = React.useMemo(() => {
    return {
      ...site,
      design: {
        ...site.design,
        colors: {
          ...design.colors,
          ...site.design.colors,
        },
      },
    }
  }, [design.colors, site])

  const onReset = React.useCallback(() => {
    const initialData = { ...formData }
    delete initialData.design.colors

    onSubmit(initialData)
  }, [formData, onSubmit])

  return (
    <Section title={t('designs/base:colors')}>
      <Form
        data={formData}
        onSubmit={onSubmit}
        id={`${site.id}-${site.updatedAt}`}
      >
        <Section.Body>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-8">
              {site?.design?.options?.colorTheme === 'custom' && (
                <>
                  <Heading as="h4">{t('themeColors')}</Heading>
                  <ColorPalette
                    name={`design[colors][theme]`}
                    label={t('themeColor')}
                    help={
                      isPaletteSet(colors?.danger)
                        ? t('themeColorHelp')
                        : t('designs/base:colorsNotSetHelp')
                    }
                    validColors={['100', '300', '500', '700', '900']}
                  />
                </>
              )}

              <Heading as="h4">{t('designs/base:customColors')}</Heading>

              {range(1, colorCount).map(i => (
                <ColorPalette
                  key={`custom-color${i}`}
                  name={`design[colors][color${i}]`}
                  label={t('designs/base:customColor', { number: i })}
                />
              ))}

              <div className="flex items-center gap-4">
                <Button
                  onClick={onAddColor}
                  label={t('addColor')}
                  icon="plus"
                  disabled={!canAddColor}
                />
                {!canAddColor && (
                  <p className="text-warn-500">
                    {t('maxColors', { max: MAX_COLORS })}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <Heading as="h4">{t('systemColors')}</Heading>
              <ColorPalette
                name="design[colors][neutral]"
                label={t('colorsNeutral')}
                help={
                  isPaletteSet(colors?.neutral)
                    ? t('colorsNeutralHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][danger]"
                label={t('colorsDanger')}
                help={
                  isPaletteSet(colors?.danger)
                    ? t('colorsDangerHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][warn]"
                label={t('colorsWarning')}
                help={
                  isPaletteSet(colors?.warn)
                    ? t('colorsWarningHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][success]"
                label={t('colorsSuccess')}
                help={
                  isPaletteSet(colors?.success)
                    ? t('colorsSuccessHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
            </div>
          </div>
        </Section.Body>
        <Section.Footer className="justify-between">
          <Button label={t('reset')} onClick={onReset} />
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Form>
    </Section>
  )
}
ColorsForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}
