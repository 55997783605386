import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Checkbox from '@ui/data-entry/Checkbox'

export function ThemeOptions() {
  const { t } = useTranslation('designs/adventist')

  const { watch } = useFormContext()
  const variantWatch = watch('variant')

  if (!['alps', 'minimal'].includes(variantWatch)) {
    return null
  }

  return (
    <Checkbox
      name="useSabbathColumn"
      label={t('useSabbathColumn')}
      help={t('useSabbathColumnHelp')}
    />
  )
}
