import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import Select from '@ui/data-entry/Select'

/**
 * Component for selecting the typography theme.
 *
 * @typedef {object} TTypographySelectProps
 * @property {object} site - The site object
 *
 * @param {TTypographySelectProps} props
 * @returns {JSX.Element}
 */
export function TypographySelect({ site }) {
  const { t } = useTranslation('designs/adventist')

  const isAdventistNewsThemeSupported = useFeatureFlagEnabled(
    'adventist-news-theme',
    {
      site,
    }
  )
  const isAdventistMinimalThemeSupported = useFeatureFlagEnabled(
    'adventist-minimal-theme',
    { site }
  )

  return (
    <>
      <Select
        label={t('typographyTheme')}
        help={t('typographyThemeHelp')}
        name="typography"
        shouldUnregister
        required
      >
        <Select.Placeholder label="-" />
        <Select.Option
          label="Sans: Noto Sans, Serif: Noto Serif"
          value="noto"
        />
        {isAdventistNewsThemeSupported ? (
          <Select.Option label="Sans: Inter, Serif: Noto Serif" value="inter" />
        ) : null}
        {isAdventistMinimalThemeSupported ? (
          <Select.Option label="Sans: Outfit, Serif: Outfit" value="outfit" />
        ) : null}
      </Select>
    </>
  )
}

TypographySelect.propTypes = {
  site: PropTypes.object.isRequired,
}
