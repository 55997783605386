import { isFeatureEnabled } from '@modules/feature-flags/helpers/isFeatureEnabled'

import base from '../base'
import { DesignOptions } from './appearance/DesignOptions'
import FooterForm from './appearance/FooterForm'
import HeaderForm from './appearance/HeaderForm'
import { Translate } from './appearance/Translate'
import useDesignSettingsTabs from './appearance/useDesignSettingsTabs'
import articleShowcaseLayoutFeaturedWithAside from './assets/ArticlesShowcase/featured-with-aside.svg'
import articleShowcaseLayoutMainFeature from './assets/ArticlesShowcase/main-feature.svg'
import articleShowcaseLayoutSingleWithOneColumn from './assets/ArticlesShowcase/single-with-one-colum.svg'
import articleShowcaseLayoutSingleWithTwoColumns from './assets/ArticlesShowcase/single-with-two-colums.svg'
import { getThemeColors } from './utils'

/**
 * Represents the Adventist design configuration.
 * @param {Object} options - The options for the Adventist design.
 * @param {Object} options.site - The site.
 * @param {Object} options.featureFlags - The feature flags
 * @returns {Object} - The Adventist design configuration.
 */
export default function ({ site, featureFlags } = {}) {
  const baseConfig = base({ site, featureFlags })

  const isArticleCategoriesEnabled = isFeatureEnabled(
    featureFlags?.['article-categories'],
    { site }
  )
  const isAdventistNewsThemeSupported = isFeatureEnabled(
    featureFlags?.['adventist-news-theme'],
    { site }
  )
  const isAdventistMinimalThemeSupported = isFeatureEnabled(
    featureFlags?.['adventist-minimal-theme'],
    { site }
  )
  const isDynamicCategoryResourcEnabled = isFeatureEnabled(
    featureFlags?.['dynamic-category-resource'],
    {
      site,
    }
  )
  const isSiteSearchEnabled = isFeatureEnabled(featureFlags?.['site-search'], {
    site,
  })

  const designVariant = site?.design?.options?.variant ?? 'alps'

  /**
   * IMPORTANT: keep this updated as the values in the frontend changes
   */
  return {
    ...baseConfig,
    title: 'Adventist design',
    layouts: false,
    notifictions: false,
    namespace: 'designs/adventist',

    menus: {
      itemVariants: {
        link: 'designs/adventist:menuItemVariant_link',
        button: 'designs/adventist:menuItemVariant_button',
      },
      cookieOptions: true,
    },

    appearance: {
      ...(isAdventistMinimalThemeSupported
        ? {
            useSettingsTabs: useDesignSettingsTabs, // This is a hook that returns an array of tabs
          }
        : {}),
      DesignOptions,
      HeaderForm,
      FooterForm,
      Translate,
      // TODO: Remove after `adventist-news-theme` or `adventist-minimal-theme` feature flag is removed
      themes: [
        { name: 'treefrog', label: 'Treefrog' },
        { name: 'ming', label: 'Ming' },
        { name: 'bluejay', label: 'Bluejay' },
        { name: 'iris', label: 'Iris' },
        { name: 'lily', label: 'Lily' },
        { name: 'scarlett', label: 'Scarlett' },
        { name: 'campfire', label: 'Campfire' },
        { name: 'winter', label: 'Winter' },
        { name: 'forest', label: 'Forest' },
        { name: 'cave', label: 'Cave' },
        { name: 'denim', label: 'Denim' },
        { name: 'emperor', label: 'Emperor' },
        { name: 'grapevine', label: 'Grapevine' },
        { name: 'velvet', label: 'Velvet' },
        { name: 'earth', label: 'Earth' },
        { name: 'night', label: 'Night' },
      ],
      // TODO: Remove after `adventist-news-theme` or `adventist-minimal-theme` feature flag is removed
      supportsDarkMode: true,
      // TODO: Remove after `adventist-news-theme` or `adventist-minimal-theme` feature flag is removed
      options: [
        {
          name: 'useSabbathColumn',
          label: 'useSabbathColumn',
          help: 'useSabbathColumnHelp',
        },
      ],
    },

    fonts: {
      ...baseConfig.fonts,
      weights:
        designVariant === 'news'
          ? ['light', 'normal', 'medium', 'semibold', 'bold', 'black']
          : designVariant === 'minimal'
            ? [
                'thin',
                'extralight',
                'light',
                'normal',
                'medium',
                'semibold',
                'bold',
                'extrabold',
                'black',
              ]
            : ['normal', 'bold'],
      sizes: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '6xl', '8xl'],
    },

    padding: {
      type: 'design', // options: 'all', 'design' or 'defaults'
      options: ['zero', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'],
    },

    columns: [1, 2, 3, 4, 5, 6, 7],

    blocks: {
      Root: {
        bgColor: true,
        dark: true,
      },
      Section: {
        reverseThemeAppearance: true,
        padding: 'all',
        gradient: true,
      },
      Container: {
        bgColor: true,
        border: true,
        gradient: true,
      },
      Grid: {
        showGridOptions: true,
      },
      Box: {
        backgroundColor: true,
        border: true,
        darkBackgroundColor: true,
        hidden: true,
        translationHideable: true,
        wrap: true,
      },
      Divider: true,
      Header: {
        as: true,
        dynamicTitle: ['pageLong'],
        link: ['content'],
        linkLabel: ['content'],
        subtitle: ['pageLong'],
        kicker: ['pageLong'],
        displayModes: ['content', 'pageLong'],
        ...(designVariant === 'news'
          ? { variants: { content: ['sm', 'md', 'lg'] } }
          : {}),
      },
      Hero: {
        description: true,
        imageCaption: true,
      },
      SimpleText: {
        textAlign: true,
      },
      // LabelIcon: {
      //   labelColor: true,
      //   iconColor: true,
      // },
      RichText: true,
      Image: true,
      Accordion: true,
      AccordionItem: true,
      // AccordionItemContent: true,
      // Card: {
      //   variant: true,
      //   useLargeTitle: true,
      //   useCardPadding: true,
      //   textColors: ['white', 'neutral-600'],
      // },
      Button: {
        icon: true,
        urlSource: true,
      },
      LinkList: true,
      Breadcrumbs: true,
      HtmlEmbed: true,
      ShareButton: {
        label: true,
      },
      PageList: {
        variants: ['list', 'minimal'],
        showDescription: ['list'],
        showImage: ['list'],
        roundedImage: ['list'],
      },
      Player: true,
      // Map: true,
      Avatar: {
        personSelect: true,
        showBackground: true,
        showPersonPhone: true,
        showPersonFax: true,
        showPersonDescription: true,
        showPersonEmailLink: true,
      },
      NewsletterSubscription: {
        appearance: true,
        emailLabel: {
          defaultOnEmpty: true,
        },
        termsAcceptLabel: true,
        termsLinkLabel: true,
        termsPage: true,
      },
      NewsletterConfirmation: { appearance: true },
      ...(isDynamicCategoryResourcEnabled
        ? {
            CategoriesList: {
              variants: ['list', 'tags'],
              ...(designVariant === 'news'
                ? { titleVariants: ['sm', 'md', 'lg'] }
                : {}),
            },
          }
        : {}),
      Form: true,
      FormCheckbox: true,
      FormInput: {
        files: true,
      },
      FormSelect: true,
      FormTextArea: true,
      LoginError: true,
      LoginForm: true,
      RegistrationForm: true,
      VerifyLogin: true,
      UserProfile: true,
      UserAccountOverview: true,
      GooglePSEResults: true,
      BraveSearchResults: true,
      BraveSearchForm: true,
      ...(isSiteSearchEnabled
        ? { SiteSearchForm: true, SiteSearchResults: true }
        : {}),

      // ImageGallery: true,
      CookieBlockedContent: true,

      // ChurchFinder
      ChurchFinder: {
        showAdvancedSearch: true,
        searchByEntityType: true,
      },
      ChurchDetail: true,
      ChurchHeader: true,
      ChurchContactPersons: true,

      // Articles
      ArticleList: {
        alternateFirstItem: true,
        displayModes: ['list', 'card', 'feedList', 'relatedList'],
        displayModeOptions: {
          list: {
            itemVariants: ['sm', 'md'],
            titleSizes: ['sm', 'md'],
          },
          card: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        filterCurrentArticle: true,
        itemBackgroundColor: ['card'],
        ...(designVariant === 'alps' ? { rounded: ['feedList'] } : {}),
        showCategories: true,
        showDescription: true,
        showFilters: true,
        showFlags: true,
        showImage: true,
        useCardPadding: ['card'],
      },
      CategorizedArticles: {
        alternateFirstItem: true,
        displayModes: ['list', 'card', 'feedList', 'relatedList'],
        displayModeOptions: {
          list: {
            itemVariants: ['sm', 'md'],
            titleSizes: ['sm', 'md'],
          },
          card: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        filterCurrentArticle: true,
        itemBackgroundColor: ['card'],
        rounded: ['feedList'],
        showCategories: true,
        showDescription: true,
        showFlags: true,
        showImage: true,
        useCardPadding: ['card'],
      },
      RelatedArticles: {
        alternateFirstItem: true,
        displayModes: ['list', 'card', 'feedList', 'relatedList'],
        displayModeOptions: {
          list: {
            itemVariants: ['sm', 'md'],
            titleSizes: ['sm', 'md'],
          },
          card: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        itemBackgroundColor: ['card'],
        rounded: ['feedList'],
        showCategories: true,
        showDescription: true,
        showImage: true,
        useCardPadding: ['card'],
      },
      ...(isAdventistNewsThemeSupported || isAdventistMinimalThemeSupported
        ? {
            ArticlesShowcase: {
              designVariants: ['news', 'minimal'],
              layouts: [
                {
                  code: 'main-feature',
                  // Used to apply multiple article list filtering strategies
                  filters: [
                    { code: 'main', limit: 3 },
                    { code: 'aside', limit: 3 },
                  ],
                  preview: articleShowcaseLayoutMainFeature,
                },
                {
                  code: 'single-with-one-colum',
                  // Used to apply multiple article list filtering strategies
                  filters: [{ code: 'main', limit: 3 }],
                  preview: articleShowcaseLayoutSingleWithOneColumn,
                },
                {
                  code: 'single-with-two-colums',
                  // Used to apply multiple article list filtering strategies
                  filters: [{ code: 'main', limit: 5 }],
                  preview: articleShowcaseLayoutSingleWithTwoColumns,
                },
                {
                  code: 'featured-with-aside',
                  // Used to apply multiple article list filtering strategies
                  filters: [{ code: 'main', limit: 12 }],
                  preview: articleShowcaseLayoutFeaturedWithAside,
                },
              ],
              filterCurrentArticle: true,
              showCategories: true,
              showFlags: true,
            },
          }
        : {}),
      ArticleFilters: true,
      ArticleDetail: true,
      ArticleHeader: {
        ...(designVariant === 'alps' ? { variants: ['md', 'lg'] } : {}),
        showOrganization: true,
        showFlag: true,
        locationContext: true,
      },
      ...(isAdventistNewsThemeSupported || isAdventistMinimalThemeSupported
        ? {
            ArticleImage: true,
          }
        : {}),
      ...(!isArticleCategoriesEnabled
        ? {
            ArticleFlagsList: {
              showListIcon: true,
            },
          }
        : {}),
      ArticleMap: true,
      ArticleArchive: true,

      // Media library
      EpisodeHeader: true,
      EpisodeDetail: true,
      EpisodePlayer: true,
      EpisodeAudioPlayer: true,
      EpisodesList: {
        columns: ['cards'],
        displayModes: ['list', 'relatedList', 'cards', 'scroller'],
        displayModeOptions: {
          cards: {
            itemVariants: ['sm', 'md', 'lg'],
          },
        },
        dynamic: true,
        horizontal: ['cards'],
        itemBackgroundColor: ['cards'],
        playInline: ['cards'],
        showPagination: true,
        showShow: true,
        useCardPadding: ['cards'],
      },
      EpisodeFilters: {
        dynamic: true,
      },
      LivestreamPlayer: true,
      ShowAudioPlaylist: true,

      // Events
      EventList: true,
      EventsTeaser: true,
      EventHeader: {
        variants: ['md', 'lg'],
      },
      EventLocationMap: true,
      EventDetail: true,
    },

    colors: {
      theme: {
        name: 'Theme colors',
        ...getThemeColors({
          theme:
            isAdventistNewsThemeSupported || isAdventistMinimalThemeSupported
              ? site?.design?.options?.colorTheme
              : site?.design?.theme,
          designVariant,
        }),
      },

      ...(isAdventistMinimalThemeSupported
        ? {
            color1: {},
            color2: {},
            color3: {},
            color4: {},
            color5: {},
            color6: {},
            color7: {},
            color8: {},
          }
        : {}),

      neutral: {
        name: 'Neutral colors',
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#e5e5e5',
        300: '#d4d4d4',
        400: '#a3a3a3',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717',
        950: '#0a0a0a',
      },

      ...(isAdventistMinimalThemeSupported
        ? {
            danger: {
              name: 'Danger',
              50: '#fef2f2',
              100: '#fee2e2',
              200: '#fecaca',
              300: '#fca5a5',
              400: '#f87171',
              500: '#ef4444',
              600: '#dc2626',
              700: '#b91c1c',
              800: '#991b1b',
              900: '#7f1d1d',
              950: '#450a0a',
            },
            warn: {
              name: 'Warning',
              50: '#fffbeb',
              100: '#fef3c7',
              200: '#fde68a',
              300: '#fcd34d',
              400: '#fbbf24',
              500: '#f59e0b',
              600: '#d97706',
              700: '#b45309',
              800: '#92400e',
              900: '#78350f',
              950: '#451a03',
            },
            success: {
              name: 'Success',
              50: '#f0fdf4',
              100: '#dcfce7',
              200: '#bbf7d0',
              300: '#86efac',
              400: '#4ade80',
              500: '#22c55e',
              600: '#16a34a',
              700: '#15803d',
              800: '#166534',
              900: '#14532d',
              950: '#052e16',
            },
          }
        : {}),

      system: baseConfig.colors.system,
    },

    widths: ({ useSabbathColumn } = {}) => {
      // NOTE: These widths are for columns in the frontend using the w-screen-* classes. But here in the backend we are using the w-* classes for better feedback for the user in the backend, as in the editor the widths are relative to the container width, and not the screen width.

      // If the design variant is 'news', or the `useSabbathColumn` option is disabled,
      if (designVariant === 'news' || !useSabbathColumn) {
        // then use a 6 column grid
        return {
          'auto': 'w-auto',
          'full': 'w-full',
          '1/6': 'w-1/6', // frontend is w-screen-1/6
          '2/6': 'w-2/6', // frontend is w-screen-2/6
          '3/6': 'w-3/6', // frontend is w-screen-3/6
          '4/6': 'w-4/6', // frontend is w-screen-4/6
          '5/6': 'w-5/6', // frontend is w-screen-5/6
        }
      }

      // Otherwise, use a 7 column grid by default
      return {
        'auto': 'w-auto',
        'full': 'w-full',
        '1/7': 'w-1/7', // frontend is w-screen-1/7
        '2/7': 'w-2/7', // frontend is w-screen-2/7
        '3/7': 'w-3/7', // frontend is w-screen-3/7
        '4/7': 'w-4/7', // frontend is w-screen-4/7
        '5/7': 'w-5/7', // frontend is w-screen-5/7
        '6/7': 'w-6/7', // frontend is w-screen-6/7
      }
    },

    buttons: {
      variants: ['default', 'lighter', 'outline', 'simple', 'plain'],
      sizes: ['sm', 'md'],
      iconPositions: ['left', 'right'],
    },

    form: {
      variants: [
        'light',
        'dark',
        'underline-light',
        'underline-dark',
        'underline-theme',
      ],
    },

    icons: [
      'arrow-bracket-left',
      'arrow-bracket-right',
      'arrow-long-left',
      'arrow-long-right',
      'arrow-short-left',
      'arrow-short-right',
      'asterisk',
      'cion',
      'circle-check',
      'circle-exclamation',
      'circle-info',
      'circle-x',
      'close',
      'download',
      'menu',
      'message',
      'play',
      'plus',
      'search',
      'share',
      'spinner-third',
    ],
  }
}
